<template>
  <div class="buyEdit-pwd">
    <div class="cell">
      <div>更改密码</div>
      <div class="miaosu">为了确保您的账户安全，请勿与其他人分享您的密码</div>
      <div style="
          width: 100%;
          padding: 0 60px;
          margin-top: 30px;
          display: flex;
          justify-content: center;
        ">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="当前密码">
            <el-input style="min-width: 300px" show-password v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input style="min-width: 300px" show-password v-model="form.new_pwd"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input style="min-width: 300px" show-password v-model="form.confirm_pwd"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">确认</el-button>
          </el-form-item>
        </el-form>
        <div @click="toPush" style="
            line-height: 40px;
            margin-left: 20px;
            font-size: 14px;
            color: #b0b0b0;
            cursor: pointer;
          ">
          忘记密码 ?
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changePassword } from "@/api/buy.js";
import { removeBAuth, getBAuth } from "@/utils/auth.js";
export default {
  data() {
    return {
      form: {
        password: "",
        new_pwd: "",
        confirm_pwd: "",
      },
    };
  },
  mounted() {
    if (!getBAuth()) {
      this.$router.replace("buyLogin");
    }
  },
  created() {
    this.$store.state.buyMenuIndex = 13;
  },
  methods: {
    onSubmit() {
      let _this = this;
      changePassword({
        ...this.form,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          // this.$message.success(res.message + ",请重新登录");
          // removeBAuth();
          // setTimeout(function () {
          //   _this.$router.replace("buyLogin");
          // }, 1000);
        }
      });
    },
    toPush() {
      this.$router.push("buyForget");
    },
  },
};
</script>
<style lang="less" scoped>
.buyEdit-pwd {
  padding: 20px;

  .cell {
    background: #fff;
    padding: 30px;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    .miaosu {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin: 6px 0 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #bebebe;
    }
  }
}
</style>